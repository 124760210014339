import React from 'react';
import "./Banner.css"
const Banner = () => {
    return (
        <section className="banner">
            <div className="overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h2>For all your individual and business tax needs, We are here to help</h2>
                            <p>Our team will help you for all your personal tax needs and Business tax preparation including Business tax credit claims (Employee Retention Credit, Recovery Startup Business Credit….) .</p>
                            <a href="https://boxfin.co/#/qualify" target="_blank" className="btn btn-success">Learn more</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
