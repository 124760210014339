import React from 'react';
import Data from "../../../Data";
import "./Pricing.css"

const Pricing = () => {
    const itemPrice = Data.pricing.map((itembox)=>{
        return(
            <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3">
                        <h4 className="my-0 fw-normal">{itembox.title}</h4>
                    </div>
                    <div className="card-body">
                        <h1 className="card-title pricing-card-title">Request a quote</h1>
                        <ul className="list-styled mt-3 mb-4">
                            <li>{ itembox.details }</li>
                            <li>{ itembox.extra }</li>
                            <li>{ itembox.extra2 }</li>
                            <li>{ itembox.extra3 }</li>
                        </ul>
                        <a href={itembox.link} type="button" className="w-100 btn btn-lg btn-outline-primary">Book for free
                        </a>
                    </div>
                </div>
            </div>

        )
    });

    return (
        <section className="pricing" id="pricing">

            {/*<div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Free</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">$0<small
                                className="text-muted fw-light">/mo</small></h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>10 users included</li>
                                <li>2 GB of storage</li>
                                <li>Email support</li>
                                <li>Help center access</li>
                            </ul>
                            <button type="button" className="w-100 btn btn-lg btn-outline-primary">Sign up for free
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header py-3">
                            <h4 className="my-0 fw-normal">Pro</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">$15<small
                                className="text-muted fw-light">/mo</small></h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>20 users included</li>
                                <li>10 GB of storage</li>
                                <li>Priority email support</li>
                                <li>Help center access</li>
                            </ul>
                            <button type="button" className="w-100 btn btn-lg btn-primary">Get started</button>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card mb-4 rounded-3 shadow-sm border-primary">
                        <div className="card-header py-3 text-bg-primary border-primary">
                            <h4 className="my-0 fw-normal">Enterprise</h4>
                        </div>
                        <div className="card-body">
                            <h1 className="card-title pricing-card-title">$29<small
                                className="text-muted fw-light">/mo</small></h1>
                            <ul className="list-unstyled mt-3 mb-4">
                                <li>30 users included</li>
                                <li>15 GB of storage</li>
                                <li>Phone and email support</li>
                                <li>Help center access</li>
                            </ul>
                            <button type="button" className="w-100 btn btn-lg btn-primary">Contact us</button>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="container">
                <div className="row">
                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-4 fw-normal">Book a service</h1>
                        <p className="fs-5 text-muted">Quickly need to get help, just book for it.</p>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                        {itemPrice}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
