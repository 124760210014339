import React from 'react';
import logo from "../../../assets/logo.png";
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPhone, faMapLocation, faMailReply} from '@fortawesome/free-solid-svg-icons';


const Footer = () => {
    return (
        <div className="container">
            <div className="row pt-2" id="contact">
                <div className="row pb-3">
                    <div className="col-md-4">
                        <div className="footer-address"><FontAwesomeIcon icon={faPhone}/></div>

                        <p className="footer-text"><a href="tel:+13362529496">+1 3362529496</a></p>
                    </div>
                    <div className="col-md-4">
                        <div className="footer-address"><FontAwesomeIcon icon={faMapLocation}/></div>

                        <p className="footer-text">Greensboro, NC United State</p>
                    </div>
                    <div className="col-md-4">
                        <div className="footer-address"><FontAwesomeIcon icon={faMailReply}/></div>
                        <p className="footer-text"><a href="#" title="Online Service Calculator">claak@akbusinessfinancial.com</a></p>
                    </div>
                </div>
            </div>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="col-md-4 d-flex align-items-center">
                    <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <img src={logo} alt="" className="bi" width="30" height="24"/>
                    </a>
                    <span className="mb-3 mb-md-0 text-muted">© 2023 AK Business Financial, Inc</span>
                </div>

                <p className="nav col-md-6 justify-content-end list-unstyled d-flex text-muted">
                    AK Business Financial is a trademark of A&K Global Business LLC
                </p>
            </footer>
        </div>
    );
};

export default Footer;
