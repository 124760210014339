import { faMoneyBill, faChartLine, faChartSimple, faPiggyBank } from '@fortawesome/free-solid-svg-icons';

const Data = {
    serv : [
        {
            icons: faPiggyBank,
            title: 'Individual Tax preparation',
            text: 'We help for all your personal tax needs.'
        },
        {
            icons: faMoneyBill,
            title: 'Business Registration',
            text: 'Sole Proprietorship, LLC (Partnership, Single member LLC), Non profit Organizations, SCorp….'
        },
        {
            icons: faChartSimple,
            title: 'Business Tax preparation',
            text: 'All Business tax preparation including Business tax credit claims (Employee Retention Credit, Recovery Startup Business Credit….) .'
        },
        {
            icons: faChartLine,
            title: 'Financial Consulting related services',
            text: 'Helps organizations define their strategy by identifying opportunities, improving financial planning, and benchmarking performances.'
        },
        /*{
            icons: faChartBar,
            title: 'Full-service tax preparation services',
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, laborum.'
        },
        {
            icons:faChartArea,
            title: 'Tax-related financial products',
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, laborum.'
        },
        {
            icons: faMoneyCheck,
            title: 'Other tax return preparation services',
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, laborum.'
        },
        {
            icons: faCashRegister,
            title: 'Other financial consulting and advisory related services',
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugiat, laborum.'
        },*/
    ],
    pricing : [
        {
            title: 'Tax Preparation',
            price: '$100',
            time: '',
            details: 'Maximize your Return',
            extra: '',
            extra2: '',
            extra3: '',
            link: 'https://forms.gle/Jq27iT9sbDmRxGJ17'
        },
        {
            title: 'Business Registration',
            price: '$100',
            time: '',
            details: 'Register your business name',
            extra: 'Register with the IRS',
            extra2: 'Register with state and local agencies',
            extra3: 'Apply for licenses and permits',
            link: 'https://forms.gle/Jq27iT9sbDmRxGJ17'
        },
        {
            title: 'Business Tax Credits',
            price: '$100',
            time: '',
            details: 'Employee Retention Credit ',
            extra: 'Research and Development credit',
            extra2: 'Recovery Startup Business Credit',
            extra3: 'Other credits and incentives',
            link: 'https://forms.gle/Jq27iT9sbDmRxGJ17'
        }
    ]
};

export default Data;
